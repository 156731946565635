import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"
import Grid from "../../components/grid"

const Ausstellungseroeffnnung = () => {
  const data = useStaticQuery(graphql`
    query {
      poster: file(
        relativePath: { eq: "videos/poster-ausstellungseroeffnung.jpg" }
      ) {
        ...faktenPosterImage
      }
    }
  `)

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo title="Digitale Ausstellungseröffnung" description="" />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digitale Ausstellungseröffnung",
              link: "/neuigkeiten/hoelderlin-liebt-ausstellungseroeffnnung",
            },
          ]}
        />
        <PageTitle>Digitale Ausstellungseröffnung</PageTitle>
        <Grid columns={[1, 2]}>
          <Stack>
            <Paragraph dropcap={true}>
              Zur Eröffnung der Sonderausstellung ›Hölderlin liebt...‹ ist ein
              Film mit Übersetzungen in Gebärdensprache entstanden. Mit einem
              Beitrag von Dr. Daniela Harsch (Bürgermeisterin für Soziales,
              Ordnung und Kultur der Universitätsstadt Tübingen) und Beiträgen
              von Stephanie Jaeckel (Autorin für Einfache Sprache), Jette von
              Bodecker (Illustratorin und Gestalterin der Ausstellung) und Dr.
              Daniela Happ (gehörlose Gebärdensprach-Dolmetscherin), die von
              ihrer Mitarbeit an der Ausstellung berichten.
            </Paragraph>
            <Paragraph>
              Außerdem enthält der Film eine Kurzführung durch die Ausstellung
              mit der Museumsleiterin Dr. Sandra Potsch.
            </Paragraph>
          </Stack>
          <VideoPlayer
            src="hoelderlin-liebt/ausstellungseroeffnung"
            poster={data.poster}
          />
        </Grid>
      </Stack>
    </Layout>
  )
}

export default Ausstellungseroeffnnung
